

input, .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 16px;
    font-family: PoppinsRegular;
    border: 1px solid rgba(34,36,38,.15);
    outline: 0;
    border-radius: .28571429rem;
    background: white;
}

input::placeholder {
    color: rgba(0,0,0,.87);
}
input:focus {
    border: 1px solid rgba(34,36,38,.15);
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}
.StripeElement--focus {
    border: 1px solid rgba(34,36,38,.15);
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}
.StripeElement {
    &.PaymentRequestButton {
        height: 40px;
        padding: 0;
    }
    
}


.cl-12 {
    width: 75%!important;
    padding-left: .5em;
    padding-right: .5em;
}
.cl-4 {
    width: 25%!important;
    padding-left: .5em;
    padding-right: .5em;
}
