#root {
    height: 100%;
    width: 100%;
}

body, html {
    width: 100%;
    height: 100%;
    min-width: 315px!important;
    overflow-y: visible;
}

body {
    -webkit-font-smoothing: antialiased;
}

body ::-webkit-scrollbar {
    -webkit-appearance: inherit!important;
    width:5px;
}

* {
    padding: 0;
    margin: 0;
}

.brand {
    color:var(--brand-secondary)!important;
}
.brand-primary {
    color:var(--brand-primary)!important;
}

.brand-error {
    color:var(--brand-error)!important;
}
.brand-primary-light{
    color: var(--brand-primary-light)!important;
}
.brand-alarm {
    color:var(--brand-alarm);
}

.alignCenter {
    text-align: center;
}

.captcha {
    @media screen and (max-width: 400px) {
        transform: scale(0.8);
        transform-origin: top left;
        width: 270px;
    }
}

.fWeightNormal {
    font-weight: normal!important;
}

.asLink {
    cursor: pointer;
    text-decoration: underline;
}

.light-grey-2 {
    color: var(--light-grey-2)!important;;
}

.white {
    color: #fff!important;
}

.disabled-scroll {
    @media only screen and (max-width: 767px){
        overflow: hidden;
    }
}

.inline-block{
    display: inline-block;
}

.c-pointer {
    cursor: pointer;
}

.pdL1 {
    padding-left: 1rem;
}

.no-style-list {
    list-style: none;
    padding-left:1rem;
}

.rotate45{
    transform: rotate(45deg);
}
.rotate225{
    transform: rotate(225deg);
}

.m-auto {
    margin: auto;
}

.Toastify__toast{
    font-family: PoppinsRegular;
}

.Toastify__toast--success {

    &.r-toast-success {
        background: var(--brand-positive);
    }

    &.r-toast-error {
        background: var(--red-color-secondary);
    }
}

.ygbIcon {
    width: 37px;
    display: inline-block!important;
}

.wbk{
    word-break: break-all;
}

.ui.form .field>label{
    font-family: var(--font-secondary);
}
.ui.checkbox label, .ui.checkbox+label {
    font-family: var(--font-secondary);
}

.font-secondary {
    font-family: var(--font-secondary)!important;
}

.w200 {
    width:200px;
}

.i-back {
    display: inline-flex;
    margin-bottom: 10px;
    cursor: pointer;
    i {
        margin-right: 5px;
        height: auto;
        &:before {
            font-size: 25px;
            color:#242a42;
            opacity: 0.5;
        }
    }
    span {
        font-family: PoppinsRegular;
        font-size: 14px;
        color: var(--y-grey3);
    }
    @media only screen and (max-width: 920px) {
        display: none;
      }

    &.notHide{
        display: inline-flex!important;
    }
}